import { Col, Row } from 'emotion-flex';
import React, { FC } from 'react';
import Container from '../Reimbursement/Container';
import {
  MonoFontLabel,
  OpenSansParagraph,
  RecoletaHeading,
} from '../common/typography';
import { markdownText } from '../../pages/doctors';
import { ButtonLink } from '../common/Button';
import CaraCareVideo from '../../assets/svgs/product/img-video_thumb-linda.png';
import VideoOverlay from '../VideoOverlay';
import presets from '../../styles/presets';
import { useIntl } from 'react-intl';
import { typeformEligiblityCheckerLink } from '../../utils/constants';

const Header: FC = () => {
  const intl = useIntl();

  return (
    <Container css={{ maxWidth: 1100 }}>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 40,

            [presets.md]: {
              marginTop: 0,
              textAlign: 'left',
              alignItems: 'flex-start',
            },
          }}
        >
          <MonoFontLabel
            css={{
              fontWeight: 700,
              letterSpacing: '2px',
              lineHeight: '18px',
              color: '#235544',
            }}
          >
            {intl.formatMessage({
              id: 'product.page.header.prefix',
              defaultMessage: 'Verschreibung, Funktionen & Inhalte',
            })}
          </MonoFontLabel>
          <RecoletaHeading
            css={{
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '38px',
            }}
          >
            {markdownText(
              intl.formatMessage({
                id: 'product.page.header.title',
                defaultMessage:
                  'Linda erklärt euch<br/> <span style="color: #2AAB3C">Cara Care für Reizdarm</span>',
              })
            )}
          </RecoletaHeading>
          <OpenSansParagraph
            css={{
              maxWidth: 400,
            }}
          >
            {intl.formatMessage({
              id: 'product.page.header.text',
              defaultMessage:
                'Wir helfen dir Schritt für Schritt zur signifikanten Reduktion deiner Symptome und verbesserter Lebensqualität.',
            })}
          </OpenSansParagraph>
          <ButtonLink
            to={typeformEligiblityCheckerLink}
            css={{
              marginTop: 20,
              width: 280,
            }}
          >
            {intl.formatMessage({
              id: 'product.page.header.cta.text',
              defaultMessage: 'Jetzt Eignung testen',
            })}
          </ButtonLink>
          <OpenSansParagraph
            css={{
              marginTop: 20,
              maxWidth: 400,
            }}
          >
            {intl.locale === 'en' &&
              intl.formatMessage({
                id: 'new.landingpage.disclaimer',
                defaultMessage:
                  '*Therapy is currently only available in German.',
              })}
          </OpenSansParagraph>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <VideoOverlay
            buttonType="image"
            link="https://www.youtube.com/embed/DCofRWr6jaM"
            thumbnail={CaraCareVideo}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
